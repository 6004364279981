import {Injectable} from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import {from, Observable} from "rxjs";
import {NbAuthService} from "@nebular/auth";
import {switchMap} from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: NbAuthService) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.authService.getToken();

    return from(token).pipe(
      switchMap((authToken) => {
        if (authToken.isValid()) {
          const cloned = request.clone({
            setHeaders: {
              Authorization: `Bearer ${authToken.getValue()}`,
            },
          });
          return next.handle(cloned);
        } else {
          // If the token is not valid, just forward the request without modification
          return next.handle(request);
        }
      }),
    );
  }
}
