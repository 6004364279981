import { Injectable, NgZone } from "@angular/core";
import { OneSignal } from "onesignal-ngx";
import { environment } from "../../../../environments/environment";
import { fromEventPattern } from "rxjs";
import { NbAuthService } from "@nebular/auth";
import {
  OrderItemDetails,
  OrdersDataResponse,
} from "../../../@core/data/order";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PusherService {
  private oneSignalAppId = environment.oneSignalAppId;
  private audio: HTMLAudioElement;

  constructor(
    private oneSignal: OneSignal,
    private authService: NbAuthService,
    private router: Router,
    private zone: NgZone
  ) { }

  public init() {
    this.initializeOneSignal();
    this.initializeAudio();
  }

  private initializeOneSignal() {
    console.log("Initializing OneSignal...");
    this.oneSignal
      .init({ appId: this.oneSignalAppId })
      .then(() => {
        console.log("OneSignal Initialized");
        this.requestNotificationPermissions();
        this.setupForegroundNotificationHandler();
        this.setupNotificationClickHandler();
        this.setupNotificationCloseHandler();
      })
      .catch(this.handleInitializationError);
  }

  private initializeAudio() {
    this.audio = new Audio("assets/clips/notification1.mp3");
    this.audio.loop = true;
  }

  private setupForegroundNotificationHandler() {
    fromEventPattern(
      (handler) =>
        this.oneSignal.Notifications.addEventListener(
          "foregroundWillDisplay",
          handler
        ),
      (handler) =>
        this.oneSignal.Notifications.removeEventListener(
          "foregroundWillDisplay",
          handler
        )
    ).subscribe(
      (event) => {
        console.log("Foreground notification event:", event);
        this.handleForegroundNotification(event);
      },
      (error) => {
        console.error("Error in foreground notification handler:", error);
      }
    );
  }

  private setupNotificationClickHandler() {
    fromEventPattern(
      (handler) =>
        this.oneSignal.Notifications.addEventListener("click", handler),
      (handler) =>
        this.oneSignal.Notifications.removeEventListener("click", handler)
    ).subscribe(
      (event) => {
        console.log("Notification click event:", event);
        this.handleNotificationClick(event);
      },
      (error) => {
        console.error("Error in notification click handler:", error);
      }
    );
  }

  private setupNotificationCloseHandler() {
    fromEventPattern(
      (handler) =>
        this.oneSignal.Notifications.addEventListener("dismiss", handler),
      (handler) =>
        this.oneSignal.Notifications.removeEventListener("dismiss", handler)
    ).subscribe(
      (event) => {
        console.log("Notification dismiss event:", event);
        this.handleNotificationDismiss(event);
      },
      (error) => {
        console.error("Error in notification dismiss handler:", error);
      }
    );
  }

  private handleForegroundNotification(event: any) {
    console.log("Handling foreground notification:", event.notification);
    this.playAudio();
  }

  private handleNotificationClick(event: any) {
    this.stopAudio();
    this.processNotificationData(event.notification);
  }

  private handleNotificationDismiss(event: any) {
    console.log("Handling notification dismiss:", event.notification);
    this.stopAudio();
  }

  private handleInitializationError(error: any) {
    console.error("OneSignal Initialization Error:", error);
  }

  public logout() {
    this.oneSignal
      .logout()
      .then(() => console.log("OneSignal Logged out"))
      .catch(this.handleLogoutError);
  }

  private handleLogoutError(error: any) {
    console.error("OneSignal Logout Error:", error);
  }

  private processNotificationData(data: any) {
    console.log("Processing notification data:", data);
    const additionalData = data.additionalData;
    const orderProps = JSON.parse(additionalData.OrderProps);

    const orderItemsList: OrderItemDetails[] = orderProps.map((item: any) => ({
      arabicName: item.ArabicName,
      englishName: "",
      priceWithVat: item.PriceWithVat,
      quantity: item.Quantity,
      itemsTotalAmount: 0,
      imagePath: "",
    }));

    const order: OrdersDataResponse = {
      id: additionalData.OrderId,
      clientName: additionalData.ClientName,
      clientPhone: additionalData.ClientPhone,
      creationDate: additionalData.CreationDate,
      deliveryAddress: additionalData.DeliveryAddress,
      orderNote: additionalData.OrderNote,
      orderNumber: additionalData.OrderNumber,
      orderType: additionalData.OrderType,
      totalAmount: additionalData.PriceWithVat,
      orderItems: orderItemsList,
      status: 0,
      orderTotalNumber: 0,
    };

    this.zone.run(() => {
      this.router.navigate(["pages/accounts/orders/view-orders", order.id]);
    });
  }

  public setExternalUserId_v2() {

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('OneSignalSDKWorker.js')
      .then(function(registration) {
          console.log('Service Worker registered with scope:', registration.scope);
      }).catch(function(error) {
          console.error('Service Worker registration failed:', error);
      });
  }

    this.authService.getToken().subscribe((token) => {
      console.log("Token information:", token);

      const userId =
        token.getPayload()[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"
        ];
      if (userId) {
        console.log("Setting external user ID:", userId);
        this.oneSignal
          .login(userId)
          .then(() => console.log("OneSignal Logged in"))
          .catch(this.handleLoginError);
      } else {
        console.error("User ID not found in token payload");
      }
    }, (error) => {
      console.error("Error retrieving token:", error);
    });
  }


  public setExternalUserId() {
    this.authService.getToken().subscribe((token) => {
      const userId =
        token.getPayload()[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"
        ];
      this.oneSignal
        .login(userId)
        .then(() => console.log("OneSignal Logged in"))
        .catch(this.handleLoginError);
    });
  }

  private handleLoginError(error: any) {
    console.error("OneSignal Login Error:", error);
  }

  private async playAudio() {
    try {
      await this.audio.play();
      setTimeout(() => {
        this.stopAudio();
      }, 5000);
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  }

  private stopAudio() {
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0; // Reset the audio to the beginning
    }
  }
  private requestNotificationPermissions() {
    this.oneSignal.isPushNotificationsEnabled().then((isEnabled) => {
      if (!isEnabled) {
        this.oneSignal.promptForPushNotificationsWithUserResponse().then((response) => {
          console.log("User response to notification permission prompt:", response);
        });
      }
    });
  }
}
