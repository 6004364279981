<h1 id="title" class="title">تسجيل الدخول</h1>
<p class="sub-title">مرحبا بك في تطبيق حي !!</p>
<p class="sub-title">الرجاء تسجيل الدخول عن طريق رقم الهاتف و كلمة المرور</p>

<!--<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">-->
<!--  <p class="alert-title"><b>Oh snap!</b></p>-->
<!--  <ul class="alert-message-list">-->
<!--    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>-->
<!--  </ul>-->
<!--</nb-alert>-->

<!--<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">-->
<!--  <p class="alert-title"><b>Hooray!</b></p>-->
<!--  <ul class="alert-message-list">-->
<!--    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>-->
<!--  </ul>-->
<!--</nb-alert>-->

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-username">رقم الهاتف : </label>
    <input
      nbInput
      fullWidth
      [(ngModel)]="user.username"
      #username="ngModel"
      name="username"
      id="input-username"
      pattern="^05[0-9]{8}$"
      placeholder="رقم الهاتف"
      fieldSize="large"
      autofocus
      [status]="
        username.dirty ? (username.invalid ? 'danger' : 'success') : 'basic'
      "
      [required]="getConfigValue('forms.validation.username.required')"
      [attr.aria-invalid]="username.invalid && username.touched ? true : null"
    />
    <ng-container *ngIf="username.invalid && username.touched">
      <p class="caption status-danger" *ngIf="username.errors?.required">
        الرجاء ادخال رقم الهاتف
      </p>
      <p class="caption status-danger" *ngIf="username.errors?.pattern">
        الرجاء ادخال رقم هاتف صحيح
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">كلمة المرور : </label>
      <!-- <a class="forgot-password caption-2" routerLink="../request-password">Forgot Password?</a> -->
    </span>
    <input
      nbInput
      fullWidth
      [(ngModel)]="user.password"
      #password="ngModel"
      name="password"
      type="password"
      id="input-password"
      placeholder="كلمة المرور"
      fieldSize="large"
      [status]="
        password.dirty ? (password.invalid ? 'danger' : 'success') : 'basic'
      "
      [required]="getConfigValue('forms.validation.password.required')"
      [minlength]="getConfigValue('forms.validation.password.minLength')"
      [maxlength]="getConfigValue('forms.validation.password.maxLength')"
      [attr.aria-invalid]="password.invalid && password.touched ? true : null"
    />
    <ng-container *ngIf="password.invalid && password.touched">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        الرجاء إدخال كلمة المرور الخاصة بك
      </p>
      <p
        class="caption status-danger"
        *ngIf="password.errors?.minlength || password.errors?.maxlength"
      >
        Password should contain from
        {{ getConfigValue("forms.validation.password.minLength") }} to
        {{ getConfigValue("forms.validation.password.maxLength") }}
        characters
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <nb-checkbox
      name="rememberMe"
      [(ngModel)]="user.rememberMe"
      *ngIf="rememberMe"
      >Remember me</nb-checkbox
    >
  </div>

  <button
    nbButton
    fullWidth
    status="primary"
    size="large"
    [class.btn-pulse]="submitted"
  >
    تسجيل الدخول
  </button>
</form>

<!-- Add the spinner here -->
<div *ngIf="isLoading" class="loading-spinner">
  <nb-spinner></nb-spinner>
</div>

<!--<section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">-->
<!--  or enter with:-->
<!--  <div class="socials">-->
<!--    <ng-container *ngFor="let socialLink of socialLinks">-->
<!--      <a *ngIf="socialLink.link"-->
<!--         [routerLink]="socialLink.link"-->
<!--         [attr.target]="socialLink.target"-->
<!--         [attr.class]="socialLink.icon"-->
<!--         [class.with-icon]="socialLink.icon">-->
<!--        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>-->
<!--        <ng-template #title>{{ socialLink.title }}</ng-template>-->
<!--      </a>-->
<!--      <a *ngIf="socialLink.url"-->
<!--         [attr.href]="socialLink.url"-->
<!--         [attr.target]="socialLink.target"-->
<!--         [attr.class]="socialLink.icon"-->
<!--         [class.with-icon]="socialLink.icon">-->
<!--        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>-->
<!--        <ng-template #title>{{ socialLink.title }}</ng-template>-->
<!--      </a>-->
<!--    </ng-container>-->
<!--  </div>-->
<!--</section>-->

<!--<section class="another-action" aria-label="Register">-->
<!--  Don't have an account? <a class="text-link" routerLink="../register">Register</a>-->
<!--</section>-->
