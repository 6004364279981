import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import {
  NB_AUTH_OPTIONS,
  NbAuthResult,
  NbAuthService,
  NbAuthSocialLink,
  NbLoginComponent,
  getDeepFromObject,
} from "@nebular/auth";
import { Router } from "@angular/router";
import { RedirectService } from "../services/general-purposes/redirect.service";
import { NbToastrService } from "@nebular/theme";
import { PusherService } from "../../services/core/pusher.service";

@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends NbLoginComponent {
  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = "jwtAuthStrategy";
  isLoading: boolean = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  socialLinks: NbAuthSocialLink[] = [];
  rememberMe = false;

  constructor(
    service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private redirectService: RedirectService,
    private toastrService: NbToastrService,
    private pusher: PusherService
  ) {
    super(service, (options = {}), cd, router);
  }

  login(): void {
    if (!this.user.username || !this.user.password) {
      this.showToast("الرجاء إدخال رقم الهاتف و كلمة السر", 3000);
      return;
    }

    this.isLoading = true;
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service
      .authenticate(this.strategy, this.user)
      .subscribe((result: NbAuthResult) => {

        //console.log(`the result form login is :  ${JSON.stringify(result.getToken().getPayload()['unique_name'])}`);
        this.isLoading = false;
        if (result.isSuccess()) {
          var userName = result.getToken().getPayload()['unique_name'];
          localStorage.setItem('logined_userName', userName);
       //   this.pusher.setExternalUserId();
          this.pusher.setExternalUserId_v2();
          const redirectUrl = this.redirectService.getRedirectUrl();
          if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
          } else {
            this.router.navigate(["dashboard"]);
          }
        } else {
          this.showToast(result.getErrors()[0], 5000, "danger");
        }
      });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  showToast(message: string, duration: number, status: string = "warning") {
    this.toastrService.show("", ` ${message}`, { duration, status });
  }
}
