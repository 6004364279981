import { Observable } from 'rxjs';
import { IRole } from './role';

export interface User {
  name: string;
  picture: string;
}

export interface Contacts {
  user: User;
  type: string;
}

export interface CreateUserRequest{
  name:string;
  email:string;
  phone:string;
  password:string;
  rolesNames:string[];
  branchId:number;
}

export interface AddUserRoleRequest{
  phoneNumber:string;
  roles:string[];
}

export interface UserResponse{
  fullName:string;
  userName:string;
  phoneNumber:string;
  rolesNames:string[];
  branch?:string;
}

export interface RecentUsers extends Contacts {
  time: number;
}

export abstract class UserData {
  abstract getUsers(): Observable<User[]>;
  abstract getContacts(): Observable<Contacts[]>;
  abstract getRecentUsers(): Observable<RecentUsers[]>;
}


