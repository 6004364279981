import { Injectable } from '@angular/core';

@Injectable()
export class PeriodsService {
  getYears() {
    return [
      '2010', '2011', '2012',
      '2013', '2014', '2015',
      '2016', '2017', '2018',
    ];
  }

  getMonths() {
    return [
      'يناير', 'فبراير', 'مارس',
      'ابريل', 'مايو', 'يونيو',
      'يوليو', 'اغسطس', 'سبتمبر',
      'اكتوبر', 'نوفمبر', 'ديسمبر',
    ];
  }

  getWeeks() {
    return [
      'الاثنين',
      'الثلاثاء',
      'الاربعاء',
      'الخميس',
      'الجمعة',
      'السبت',
      'الاحد',
    ];
  }
}
