import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {


  private redirectUrl: string | null = null;
  private readonly redirectUrlKey = 'redirectUrl';

  constructor() {
  }

  setRedirectUrl(url: string) {
    localStorage.setItem(this.redirectUrlKey, url);
  }

  getRedirectUrl(): string | null {
    const url = localStorage.getItem(this.redirectUrlKey);
    this.clearRedirectUrl();  // Clear after retrieval
    return url;
  }

  private clearRedirectUrl() {
    localStorage.removeItem(this.redirectUrlKey);
  }
}
