import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {IBusiness} from '../models/bussiness';
import {BehaviorSubject} from 'rxjs';
import { ICommercialBranches } from '../../branches/models/BranchesModels';
import { IGeneralResponse } from '../../../@core/data/GeneralResponse';

@Injectable({
  providedIn: 'root',
})
export class BussinessDataService {

  baseUrl = environment.baseUrl;

  businesses = new BehaviorSubject<IBusiness[]>([]);

  public get businesses$() {
    return this.businesses.asObservable();
  }

  constructor(private http: HttpClient) {
  }

  loadBusinesses() {
    this.http.get<IBusiness[]>(`${this.baseUrl}commercial/salesman/commercials`)
      .subscribe((result: IBusiness[]) => {
        this.businesses.next(result);
      });
  }
  
  submitFormData(formData: FormData) {
    return this.http.post(`${this.baseUrl}commercial`, formData);
  }

 // load all Commercial branches
  loadBussinesBranches(){
    return this.http.get<ICommercialBranches[]>(`${this.baseUrl}branches`);
  }

}
